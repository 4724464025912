'use client';

import Link from "next/link";
import { usePathname } from "next/navigation";
import { Button } from "./ui/button";
import { cn } from "~/lib/utils";
import { Menu, X } from "lucide-react";
import { useState, useEffect } from "react";

export default function Navigation() {
  const pathname = usePathname();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Prevent scrolling when mobile menu is open
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [mobileMenuOpen]);

  // const navigation = [
  //   { name: "Home", href: "/" },
  //   { name: "Medical", href: "/medical" },
  //   { name: "Legal", href: "/legal" },
  //   { name: "Security", href: "/security" },
  // ];

  return (
    <>
      <header className="fixed inset-x-0 top-0 z-40 bg-white border-b border-gray-100">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Whisperit</span>
              <img
                className="h-7 w-auto"
                src="/logo_light.svg"
                alt="Whisperit company logo"
              />
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span className="sr-only">Toggle menu</span>
              {mobileMenuOpen ? (
                <X className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>

          {/* Desktop navigation */}
          {/* <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className={cn(
                  "text-sm font-semibold leading-6 text-gray-900 hover:text-gray-600 transition-colors",
                  pathname === item.href && "text-blue-600 hover:text-blue-500"
                )}
              >
                {item.name}
              </Link>
            ))}
          </div> */}
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Button asChild>
              <Link href="/meet-us">Book a demo</Link>
            </Button>
          </div>
        </nav>
      </header>

      {/* Mobile menu overlay */}
      {mobileMenuOpen && (
        <div className="fixed inset-0 z-50 bg-black/25 backdrop-blur-sm lg:hidden" 
             aria-hidden="true" 
             onClick={() => setMobileMenuOpen(false)} 
        />
      )}

      {/* Mobile menu panel */}
      <div
        className={cn(
          "fixed inset-y-0 right-0 z-50 w-full transform overflow-y-auto bg-white px-6 py-6 transition-transform duration-300 ease-in-out lg:hidden",
          mobileMenuOpen ? "translate-x-0" : "translate-x-full"
        )}
      >
        <div className="flex items-center justify-between">
          <Link href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Whisperit</span>
            <img
              className="h-8 w-auto"
              src="/logo_light.svg"
              alt="Whisperit company logo"
            />
          </Link>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <X className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            {/* <div className="space-y-2 py-6">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className={cn(
                    "-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50",
                    pathname === item.href && "text-blue-600 bg-gray-50"
                  )}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {item.name}
                </Link>
              ))}
            </div> */}
            <div className="py-6">
              <Button asChild className="w-full">
                <Link href="/meet-us" onClick={() => setMobileMenuOpen(false)}>
                  Book a demo
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
