'use client';

import { useEffect } from 'react';

export function TestimonialSection() {
  useEffect(() => {
    // Create and append the script
    const script = document.createElement('script');
    script.src = 'https://widget.senja.io/widget/16328a04-95e4-433e-ad1a-dcc367cbefd1/platform.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <section className="py-20 md:py-28 bg-white">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col items-center gap-8 md:gap-12">
          <div className="w-full max-w-3xl text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-8">What our clients say</h2>
            <p className="text-lg text-gray-600 leading-relaxed mb-8">Discover how legal professionals are saving time and delivering better results with Whisperit</p>
          </div>
        </div>
        
        <div 
          className="senja-embed" 
          data-id="16328a04-95e4-433e-ad1a-dcc367cbefd1" 
          data-mode="shadow" 
          data-lazyload="false" 
          style={{ display: 'block' }}
        />
      </div>
    </section>
  );
}
