'use client';

import React from 'react';
import { Clock, X, Scale, type LucideIcon } from 'lucide-react';

// Map of icon names to their corresponding Lucide components
const iconMap: Record<string, LucideIcon> = {
  Clock,
  X,
  Scale,
  // Add more icons as needed
};

interface Problem {
  icon: string;
  title: string;
  description?: string;
}

interface ProblemAgitationProps {
  title: string;
  description?: string;
  problems: Problem[];
  ctaText?: string;
  ctaLink?: string;
}

export function ProblemAgitation({ title, description, problems, ctaText, ctaLink }: ProblemAgitationProps) {
  return (
    <section className="py-20 md:py-28 bg-primary text-white">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col md:flex-row items-center justify-between md:space-x-20">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            {/* <h3 className="text-sm font-semibold uppercase tracking-wide mb-2">PROBLEM</h3> */}
            <h2 className="text-3xl md:text-4xl font-bold mb-4">{title}</h2>
            <p className="text-lg text-gray-200 mb-6">{description}</p>
            {ctaText && ctaLink && (
              <a href={ctaLink} className="hidden md:inline-block bg-white text-primary font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-300">
                {ctaText}
              </a>
            )}
          </div>
          <div className="w-full md:w-1/2">
            <div className="space-y-6">
              {problems.map((problem, index) => {
                const Icon = iconMap[problem.icon];
                return (
                  <div key={index} className="flex items-start">
                    {Icon && <Icon className="w-6 h-6 text-white mr-4 mt-1 flex-shrink-0" />}
                    <div>
                      <h3 className="text-xl font-semibold">{problem.title}</h3>
                      <p className="text-gray-200">{problem.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {ctaText && ctaLink && (
          <div className="mt-8 md:hidden">
            <a href={ctaLink} className="inline-block bg-white text-primary font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-300">
              {ctaText}
            </a>
          </div>
        )}
      </div>
    </section>
  );
}
