import Image from 'next/image';
import Link from 'next/link';

interface IndustryCardProps {
  title: string;
  image: string;
  link: string;
}

export function IndustryCard({ title, image, link }: IndustryCardProps) {
  return (
    <Link href={link} className="relative overflow-hidden rounded-lg group">
      <Image src={image} alt={title} width={300} height={200} className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110" />
      <div className="absolute inset-0 bg-gray-50 bg-opacity-10 flex items-end justify-between p-4">
        <h3 className="text-white font-bold bg-black bg-opacity-50 px-3 py-2 rounded flex items-center">
          {title}
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </h3>
      </div>
    </Link>
  );
}
