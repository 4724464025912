"use client";

import React, { useState } from "react";
import Image from "next/image";
import {
  type LucideIcon,
  Scale,
  Zap,
  Gem,
  Palette,
  ShieldCheck,
  AudioLines,
  FileDown,
  Server,
  Lock,
  Shield,
  CheckCircle,
  ShieldAlert,
  ShieldOff,
  ShieldPlus,
  ShieldX,
  Cpu,
} from "lucide-react";

// Map of icon names to their corresponding Lucide components
const iconMap: Record<string, LucideIcon> = {
  Scale,
  Zap,
  Gem,
  Palette,
  ShieldCheck,
  AudioLines,
  FileDown,
  Server,
  Lock,
  Shield,
  CheckCircle,
  ShieldAlert,
  ShieldOff,
  ShieldPlus,
  ShieldX,
  Cpu,
  // Add more icons as needed
};

interface Feature {
  icon: string;
  title: string;
  description: string;
}

interface FeatureAccordionSectionProps {
  title: string;
  subtitle: string;
  features: Feature[];
  imageAlt: string;
  imageUrl: string;
  imageOnRight?: boolean;
}

export function FeatureAccordionSection({
  title,
  subtitle,
  features,
  imageAlt,
  imageUrl,
  imageOnRight = false,
}: FeatureAccordionSectionProps) {
  const [activeFeature, setActiveFeature] = useState<number | null>(null);

  const toggleFeature = (index: number) => {
    setActiveFeature(activeFeature === index ? null : index);
  };

  return (
    <section className="relative w-full overflow-hidden py-20 md:py-28">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div
          className={`flex flex-col ${
            imageOnRight ? "md:flex-row-reverse" : "md:flex-row"
          } items-center gap-8 md:gap-12 lg:gap-16`}
        >
          <div className="w-full md:w-1/2">
            <h3 className="mb-2 text-sm font-semibold uppercase tracking-wide text-primary">
              {subtitle}
            </h3>
            <h2 className="mb-8 text-3xl font-bold md:text-4xl">{title}</h2>

            <div className="space-y-4">
              {features.map((feature, index) => {
                const Icon = iconMap[feature.icon];
                return (
                  <div key={index} className="rounded-lg bg-white shadow">
                    <div className="flex w-full items-center px-4 py-3 text-left sm:px-6 sm:py-4">
                      <div className="flex items-center">
                        {Icon && <Icon className="mr-3 h-5 w-5 text-primary sm:mr-4 sm:h-6 sm:w-6" />}
                        <span className="font-medium">{feature.title}</span>
                      </div>
                    </div>
                    <div className="px-4 pb-3 sm:px-6 sm:pb-4">
                      <p className="text-gray-600">{feature.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="relative mt-8 w-full md:mt-0 md:w-1/2">
            <div className="relative h-[400px] w-full overflow-hidden rounded-lg md:h-[500px] lg:h-[600px]">
              <Image
                src={imageUrl}
                alt={imageAlt}
                className="object-contain"
                fill
                sizes="(max-width: 768px) 100vw, 50vw"
                priority
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
